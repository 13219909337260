{{~removewhitespace~}}
<div id="nomessages" style="display:none;">
    You have no notifications
</div>
{{#each data}}
<li class="dropdown-submenu notification-item {{ackedClass}} {{trashedClass}}" not_id="{{ID}}">
    <div class="notification-card">
        <div class="subject-top">
            <div class="notification-content-text">
                <span class="notification-title"><strong>{{Subject}}</strong></span>


            </div>
        </div>
        <div class="notification-data">
            <ul role="button" aria-expanded="false" class="dropdown-submenu no-bullets" not_id="{{ID}}" style="display:none">
                <li>
                    <div><span class="notification-data-text">{{{Body}}}</span></div>
                </li>
            </ul>

            <ul role="button" aria-expanded="true" class="dropdown-submenu no-bullets" not_id="{{ID}}" style="display:block">
                <li>
                    <div><span class="notification-data-text">{{{shortBody}}} <span class="text-primary"><strong>...Read More</strong></span></span></div>
                </li>
            </ul>
            <div>
                <div class='like-section'>
                    <table>
                        <tr>
                            <td width="97%">
                                <span class='like-failure' aria-live="assertive"></span>
                                <span class="notification-like">

                                    <i class="icon icon-thumbs-up-alt"></i> <span class="badge like-badge" data-noteId="{{ID}}">{{LikeCount}}</span>
                                </span>
                            </td>

                            <td width="3%" text-align="right">
                                <span class="notification-trash">
                                    <i class="glyphicon glyphicon-trash"></i> <span class="trash" data-trash_id="{{ID}}"></span>
                                </span>
                            </td>
                        </tr>
                    </table>


                </div>
            </div>
        </div>
    </div>


</li>
{{/each}}