{{~removewhitespace~}}
{{#each data}}  
<tr class="tblrowcolor detailsrow"  data-group-name="{{Group}}">
                            <td>
                                <span class="btn-add-to-cart" data-type="appid" data-value="{{Value}}">
                                    <input type="checkbox"
                                           name="{{Name}}"
                                                      {{#if Selected}}
                                    checked
                                    {{/if}}
                                           >
                         
                                   
                                </span>
                                
                            </td>
                            <td>
                             {{Name}}  
                            </td>
                            <td>
                               
                            </td>
                            <td>
                             
                            </td>
                        </tr>
{{/each}}