<table class="table table-condensed" id="tblSubscriptionCart" role="presentation">
    <thead>
        <tr>
            <td></td>
            <th>{{head}}</th>
         </tr>
    </thead>


    <tbody>
        {{#each data}}
        <tr data-vppid="{{vppId}}">
            <td><a href="#" role="button" title='Remove from cart' class='glyphicon glyphicon-trash'></a></td>
            <td>{{appDisplayName}}</td>
        </tr>
        {{else}}
        <tr>
            <td></td>
            <td>No {{head}} Options Added</td>
        </tr>
        {{/each}}
    </tbody>

    <tfoot>
    </tfoot>
</table>

