{{~removewhitespace~}}
    <li class="dropdown-header" role="presentation">Quick Links:</li>
    {{#each Links}}
    <li>
        <a href="{{Url}}" role="menuitem"><span class="glyphicon glyphicon-link"></span> {{Name}}</a>
    </li>
    {{/each}}
    <li class="divider" role="presentation"></li>
    <li class="dropdown-header" role="presentation">Layers:</li>
    {{#each Layers}} {{! Could be a group or single layer }}
    {{#if GroupName}}
    <li class="dropdown-header" role="presentation">{{GroupName}}</li>
    {{#each Layers}}
    <li>
        <a href='{{LayerChangeUrl}}' class="layer-change-url" style="padding-left:36px;" title='{{#if ActiveLayer}}This is the active layer{{else}}Click to make this layer active{{/if}}' role="menuitem">
            {{#if ActiveLayer}}<span class="active-layer-indicator glyphicon glyphicon-ok"></span>{{/if}}
            {{LayerName}}
        </a>
    </li>
    {{/each}}
    {{else}}
    {{! just a normal layer }}
    <li>
        <a href='{{LayerChangeUrl}}' class="layer-change-url" title='{{#if ActiveLayer}}This is the active layer{{else}}Click to make this layer active{{/if}}' role="menuitem">
            {{#if ActiveLayer}}<span class="active-layer-indicator glyphicon glyphicon-ok"></span>{{/if}}
            {{LayerName}}
        </a>
    </li>
    {{/if}}
    {{/each}}
