<div class="modal modal-iframe" style="overflow-y:auto" role="dialog" aria-modal="true" tabindex="-1" aria-label="{{Title}}">
    <div class="modal-dialog modal-xlg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                <h2>{{Title}}</h2>
            </div>
            <div class="modal-focus-target">
                <div class="modal-body">
                    <iframe width='100%' height='500px' src="{{Url}}" style="border:none;" />
                </div>
            </div>
        </div>
    </div>
</div>