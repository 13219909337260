{{#each Layers}}
    {{#if GroupName}}
    <optgroup label="{{GroupName}}">
        {{#each Layers}}
        <option value="{{LayerId}}">{{LayerName}}</option>
        {{/each}}
    </optgroup>
    {{else}}
    <option value="{{LayerId}}">{{LayerName}}</option>
    {{/if}}
{{/each}}
