﻿{{~removewhitespace~}}
<div role="heading" aria-level="2" class="toc-header">Quick Links:</div>
{{#each Links}}
<div class="quicklink">
    <a href="{{Url}}" data-layerid="{{LayerId}}" data-pageid="{{PageId}}" title="{{Text}}">
        <span class="glyphicon glyphicon-link"></span>
        {{Name}}
    </a>
</div>
{{/each}}

<div id="txtTocHeader" role="heading" aria-level="2" class="toc-header">Layers:</div>
<ul class="toc-main" role="tree" aria-label="Layer list" aria-expanded="true">
    {{#each Layers}} {{! Could be a group or single layer }}
    {{#if GroupName}}
    <li class='grouplayer' data-groupid="{{GroupId}}" role="treeitem" aria-expanded="false">
        <span class="grouptoggle icon icon-plus-squared-alt"></span>
        <span class='name'>{{GroupName}}</span>
        <ul class="group-of-layers" style="display: none;" role="group">
            {{!  handle each layer in the group }}
            {{#each Layers}}
            <li class='layer' role="treeitem checkbox" aria-checked="false" data-layerid="{{LayerId}}">
                {{#if HasMap}}
                <span class="toc-checkbox icon icon-check-empty" />
                {{else}}
                <span class="toc-checkbox icon icon-check-empty" style="visibility:hidden;" />
                {{/if}}
                <span class='name'>
                    {{#if HasMap}}
                    {{#if Locked}}
                    {{LayerName}}
                    {{else}}
                    {{#if Query}}
                    <a href='#' class="layer-change-btn" title='Click to make this layer active' tabindex="-1" onclick='Beacon.MapJS.changeActiveLayer({{LayerId}});'>
                        {{LayerName}}
                        <span class="sr-only"> (click the / key to activate this layer)</span>
                    </a>
                    {{else}}
                    {{LayerName}}
                    {{/if}}
                    {{/if}}
                    {{else}}
                    <a href='{{LayerChangeUrl}}' class="layer-change-url" title='Click to make this layer active' tabindex="-1">
                        {{LayerName}}
                        <span class="sr-only"> (click the / key to activate this layer)</span>
                    </a>
                    {{/if}}
                </span>
            </li>
            {{/each}}
        </ul>
    </li>
    {{else}}
    <li class='layer' role="treeitem checkbox" aria-checked="false" data-layerid="{{LayerId}}">
        {{#if HasMap}}
        <span class="toc-checkbox icon icon-check-empty" />
        {{else}}
        <span class="toc-checkbox icon icon-check-empty" style="visibility:hidden;" />
        {{/if}}
        <span class='name'>
            {{#if HasMap}}
            {{#if Locked}}
            {{LayerName}}
            {{else}}
            {{#if Query}}
            <a href='#' class="layer-change-btn" title='Click to make this layer active' tabindex="-1" onclick='Beacon.MapJS.changeActiveLayer({{LayerId}});'>
                {{LayerName}}
                <span class="sr-only"> (click the / key to activate this layer)</span>
            </a>
            {{else}}
            {{LayerName}}
            {{/if}}
            {{/if}}
            {{else}}
            <a href='{{LayerChangeUrl}}' class="layer-change-url" tabindex="-1" title='Click to make this layer active'>
                {{LayerName}}
                <span class="sr-only"> (click the / key to activate this layer)</span>
            </a>
            {{/if}}
        </span>
    </li>
    {{/if}}
    {{/each}}
</ul>
<div><a id="lnkRestoreDefaults" href="#">Restore Layer Defaults</a><br /><br /></div>
