<div class="lie-line-item-panel panel panel-default">
    <div class="panel-heading clearfix">
        <span class="panel-title">{{#if item.HasParent}}<span class="lie-itemtype">{{item.ItemType}}</span> &ndash; {{item.Label}} ({{item.TaxYear}}){{else}}<strong><span class="lie-itemtype">{{item.ItemType}}</span> &ndash; {{item.Label}} ({{item.TaxYear}})</strong>{{/if}}</span>
        <button class="btn pull-right lie-delete"><span class="glyphicon glyphicon-trash"></span></button>
    </div>
    <div class="panel-body">
        <fieldset>
            <legend class="sr-only">Line item 0</legend>
            <div class="row">
                <div class="col-sm-3 form-group-sm">
                    <label class="form-label">Name</label>
                    <input class="form-control lie-label" type="text" value="{{item.Label}}" />
                </div>
                {{#if item.IsParcellaneous}}
                <div class="col-sm-3 form-group-sm">
                    <label class="form-label">Tax districts</label>
                    <select size="3" class="form-control lie-districts" multiple>
                        {{#each item.TaxDistricts}}
                        <option value="{{DistrictID}}" {{#if InDistrict}} selected{{/if}}>{{Description}}</option>
                        {{/each}}
                    </select>
                </div>
                {{else}}
                <div class="col-sm-3 form-group-sm">
                    <label class="form-label">Parent line item</label>
                    <select class="form-control lie-parenttype">
                        <option value=""{{#if item.HasParent}}{{else}} selected{{/if}}>None</option>
                        {{# each possibleParents}}
                        <option value="{{ID}}"{{#if CurrentParent}} selected{{/if}}>{{ID}} &ndash; {{Name}}</option>
                        {{/each}}
                    </select>
                </div>
                {{/if}}
                {{#if item.IsParcellaneous}}
                <div class="col-sm-3">
                    This will be populated from tax data.
                </div>
                {{else}}
                {{#if item.HasParent}}
                <div class="col-sm-3 form-group-sm">
                    <label class="form-label">Fraction of parent</label>
                    <div class="input-group">
                        <input class="form-control lie-parentfraction" type="number" max="100" min="0" step="0.1" value="{{item.ParentFraction}}" />
                        <div class="input-group-addon">%</div>
                    </div>
                </div>
                {{else}}
                <div class="col-sm-3 form-group-sm">
                    <label class="form-label">Fixed amount</label>
                    <div class="input-group">
                        <div class="input-group-addon">$</div>
                        <input class="form-control lie-fixedamount" type="number" min="0" step="0.01" value="{{item.FixedAmount}}" />
                    </div>
                </div>
                {{/if}}
                {{/if}}
                <div class="col-sm-3 form-group-sm">
                    <label class="form-label">Options</label>
                    <div class="input-group">
                        <div class="btn-group">
                            <button class="btn lie-moveup"><span class="glyphicon glyphicon-triangle-top"></span></button>
                            <button class="btn lie-movedown"><span class="glyphicon glyphicon-triangle-bottom"></span></button>
                            <button class="btn {{#if item.DisplayInRightChart}}active{{/if}} lie-displayinrightchart"
                                    role="radio"
                                    aria-checked="{{#if item.DisplayInRightChart}}true{{else}}false{{/if}}">
                                <span class="glyphicon glyphicon-modal-window"></span>
                            </button>
                            <button class="btn {{#if item.ExcludeIfZero}}active{{/if}} lie-excludeifzero"
                                    role="checkbox"
                                    aria-checked="{{#if item.ExcludeIfZero}}true{{else}}false{{/if}}">
                                <span class="glyphicon glyphicon-minus"></span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-3 form-group-sm">
                    <label class="form-label">Tax year label</label>
                    <input class="form-control lie-taxyearlabel" type="text" value="{{item.TaxYearLabel}}" />
                </div>
            </div>
        </fieldset>
    </div>
</div>