<div class="table table-striped files">
    <div>
        <div class="status">
            <span class="glyphicon glyphicon-ok" style="color: green; margin-right: 4px"></span>
        </div>
        <span class="name" data-dz-name></span>
        (<span data-dz-size></span>)
        <strong class="error text-danger" data-dz-errormessage></strong>
    </div>
    <div>
        <div class="progress progress-striped active" role="progressbar" aria-valuemin="0" aria-valuemax="100" aria-valuenow="0">
            <div class="progress-bar progress-bar-success" style="width: 0%;" data-dz-uploadprogress></div>
        </div>
    </div>
    <div>
        <button data-dz-remove class="btn btn-warning cancel dz-cancel-button">
            <i class="glyphicon glyphicon-ban-circle"></i>
            <span>Cancel</span>
        </button>
    </div>
</div>