<!--{{~#with activePage}}{{#if name}}
<li class="active" role="presentation">
    <a href="{{url}}" role="tab" aria-selected="true">
        {{name}}
        {{#if locked}} <span class="glyphicon glyphicon-lock"></span> {{/if}}
    </a>
</li>
{{/if}}{{/with}}-->
<li class="dropdown tab-item-bar" id="tabQuickZoomDropdown" style="display:none;" role="presentation">
    <a href="#" class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <span class="icon icon-layers"></span>
        Zoom <b class="caret"></b>
    </a>
    <ul class="dropdown-menu" id="tabQuickzoomList">
        <li><div id="mapJsQuickzoom">quick zoom here</div></li>
    </ul>
</li>

{{#each tabs}}

{{#if (isGroup pages)}}

<li id="{{groupID}}" {{#if hasActivePage}} class="dropdown tab-item-bar active" {{else}} class="dropdown tab-item-bar" {{/if}} role="presentation">
    <a id="{{groupID}}-btn" href="#" data-target="#" aria-controls="{{groupID}}-contents" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">{{groupName}} <b class="caret"/></a>
    <ul id="{{groupID}}-contents" class="dropdown-menu dropdown-menu-left tab-menu-container" aria-labelledby="{{groupID}}-btn">
        {{#each pages}}
        <li>
            <a
               href="{{url}}"
               role="tab"
               {{#if (equals pageType 40)}} target="_blank" rel="noopener noreferrer" {{/if}}
               {{#if isActive}} aria-selected="true" {{else}} aria-selected="false" {{/if}}
            >
                {{name}}
                {{#if locked}} <span class="glyphicon glyphicon-lock"></span> {{/if}}
            </a>
        </li>
        {{/each}}
    </ul>
</li>

{{else}}

<li id="{{groupID}}" role="presentation" {{#if hasActivePage}} class="tab-item-bar active" {{else}} class="tab-item-bar" {{/if}}>
    {{#each pages}}
    <a
       href="{{url}}"
       role="tab"
       {{#if (equals pageType 40)}} target="_blank" rel="noopener noreferrer" {{/if}}
       {{#if isActive}} aria-selected="true" {{else}} aria-selected="false" {{/if}}
    >
        {{name}}
    </a>
    {{/each}}
</li>

{{/if}}

{{/each}}

<li class="dropdown" id="tabMenuMore" role="presentation">
    <a id="tabMenuMore-btn" href="#" data-target="#" aria-controls="tabMenuMore-contents" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">More <b class="caret"></b></a>
    <ul id="tabMenuMore-contents" class="dropdown-menu dropdown-menu-right tab-menu-container" aria-labelledby="tabMenuMore-btn">
        {{#each tabs}}{{#each pages}}
        <li id="{{moreTabElementID}}" class="tab-item-menu tab-underflow">
            <a
               href="{{url}}"
               role="tab"
               {{#if (equals pageType 40)}} target="_blank" rel="noopener noreferrer" {{/if}}
               {{#if isActive}} aria-selected="true" {{else}} aria-selected="false" {{/if}}
            >
                {{name}}
                {{#if locked}} <span class="glyphicon glyphicon-lock"></span> {{/if}}
            </a>
        </li>
        {{/each}}{{/each}}
        <li class="tab-item-menu hidden-desktop"><a href="{{loginUrl}}" class="" role="tab">{{loginText}}</a></li>
    </ul>
</li>